import { useState, useEffect, useMemo, useCallback } from 'react';
import Debug from 'debug';

import { on } from '../../utils/events';

import {
  fromQueryString,
  QueryParams,
  removeHash,
  toQueryString,
} from '~/app/lib/router2';

import { INITIAL_HISTORY_STATE_GLOBAL } from './constants';

const debug = Debug('songwhip/useHash-router');

const normalizeHash = (value: string) =>
  value[0] === '#' ? value.slice(1) : value;

const parseHash = (locationHash) =>
  fromQueryString(normalizeHash(locationHash));

/**
 * Some third-party libraries (Apple MusicKit.js) use the hash fragment
 * without considering existing values, so they just append their value
 * onto ours. At app start we clean third-party duplicate hash values
 * and store them in memory exposing them via the hook result.
 *
 * This is super edge-casey and will usually just no-op.
 */
const initialDupeFragment = (() => {
  if (!process.browser) return;

  const value = normalizeHash(location.hash).replace(/%23/, '#');

  if (!~value.indexOf('#')) return;

  const [hash, dupeHash] = value.split('#');
  location.hash = hash;

  return dupeHash;
})();

const updateHistoryStateAsync = (state: {
  hashIndex?: number;
  prevHash?: string;
  hasInitialHash?: boolean;
}) => {
  setTimeout(() => {
    history.replaceState(
      {
        ...history.state,
        ...state,
      },
      '',
      location.href
    );
  });
};

let parsedHashGlobal = (() => {
  if (!process.browser) return {};

  const initialHash = location.hash;
  const initialHistoryState = window[INITIAL_HISTORY_STATE_GLOBAL] || {};
  const { hashIndex, prevHash } = initialHistoryState;

  if (hashIndex !== undefined) {
    updateHistoryStateAsync({ hashIndex, prevHash });
  }

  // flag to identify if app loads with hash in url
  // to allow backToBeforeFirstHash to properly clear the hash
  if (initialHash) {
    updateHistoryStateAsync({ hasInitialHash: true });
  }

  // Called when history navigated back/forward. Doesn't fire on .pushState()
  // or .replaceState(). This keeps our app state in-sync when the user uses
  // their back/forward buttons or we programmatically change the history index.
  on(
    window,
    'popstate',
    ({ state }: PopStateEvent) => {
      const hash = normalizeHash(location.hash);
      const { hashIndex, prevHash } = state || {};

      debug('on popstate', state, hashIndex, location.hash);

      if (hashIndex !== undefined) {
        // HACK:COMPLEX: Ensure the history entry keeps the `hashIndex` prop. This is
        // a workaround as nextjs cleans the `history.state` of any third-party data.
        // So we async override the history entry after nextjs has finished.
        updateHistoryStateAsync({ hashIndex, prevHash });
      }

      dispatchChangeEvent(hash);
    },
    true
  );

  return parseHash(initialHash);
})();

debug('init', {
  parsedHashGlobal,
});

/**
 * The hook is firing before the popstate event as nextjs has hooked into 'popstate'
 * first and is handling the route change and component mounting. When the hook
 * fires the `parseHashGlobal` is not up to date. After next has finished setting
 * up the page our 'popstate' event fires and we update `parsedHashGlobal`.
 */

interface UseHashResult {
  setHashParam: (params: QueryParams, options?: { replace?: boolean }) => void;
  removeHashParam: (key: string) => void;
  clearHash: () => void;
  backToBeforeFirstHash: () => void;
  getHashParam: (key: string) => string | undefined;
  hasHashParam: (key: string) => boolean;
  hashParams: QueryParams;
  hash: string;
  initialDupeFragment: string | undefined;
  getPrevHashParams: () => QueryParams;
}

const ENCODE = {
  encode: true,
};

const dispatchChangeEvent = (hash: string) => {
  debug('dispatch change event', { hash });
  parsedHashGlobal = parseHash(hash);

  dispatchEvent(
    new CustomEvent('appHashChange', {
      detail: {
        hash,
      },
    })
  );
};

const useHash = () => {
  const [hash, setHash] = useState(
    process.browser ? normalizeHash(location.hash) : ''
  );

  // hack ensure always up-to-date
  parsedHashGlobal = process.browser ? parseHash(location.hash) : {};

  // lame server stub
  if (!process.browser) {
    // @ts-ignore
    return {
      hasHashParam: () => {},
      hashParams: {},
    } as UseHashResult;
  }

  useEffect(() => {
    // Called when history navigated back/forward. Doesn't fire on .pushState()
    // or .replaceState(). This keeps our app state in-sync when the user uses
    // their back/forward buttons or we programmatically change the history index.
    return on(
      window,
      'appHashChange',
      ({ detail }: CustomEvent<{ hash: string }>) => {
        // hash is empty means it is not initial anymore
        if (history.state?.hasInitialHash && !detail.hash) {
          updateHistoryStateAsync({ hasInitialHash: false });
        }

        setHash(detail.hash);
      }
    );
  }, []);

  const setHashParam = useCallback<UseHashResult['setHashParam']>(
    (params, { replace } = {}) => {
      debug('set hash params', { params });

      const hashParamsNext = {
        ...parsedHashGlobal,
        ...params,
      };

      parsedHashGlobal = hashParamsNext;

      const hashString = toQueryString(
        hashParamsNext,
        // Encode special characters to avoid chars (eg. `?`,`#`) breaking the url.
        // Previously we did try encoding chars before passing to setHashParam() but
        // parsedHashGlobal is always decoded, so encoding can be lost when
        // we merge new params with existing.
        ENCODE
      );

      const prevHashIndex =
        'hashIndex' in history.state ? history.state.hashIndex : -1;

      const hashIndex = replace ? prevHashIndex : prevHashIndex + 1;

      debug('push hash', {
        hashIndex,
        prevHashIndex,
      });

      const changeHistoryState = (
        replace ? history.replaceState : history.pushState
      ).bind(history);

      changeHistoryState(
        {
          ...history.state,
          as: location.pathname + location.search + `#${hashString}`,
          prevHash: hash || undefined,
          hashIndex,
        },
        '',
        `#${hashString}`
      );

      dispatchChangeEvent(hashString);
    },
    [hash]
  );

  const clearHash = () => {
    const nextState = {
      ...history.state,
      as: removeHash(history.state.as),
    };

    delete nextState['hashIndex'];
    delete nextState['prevHash'];
    delete nextState['hasInitialHash'];

    debug('clear hash', {
      nextState,
    });

    history.pushState(nextState, '', removeHash(location.href));
    dispatchChangeEvent('');
  };

  return useMemo(
    (): UseHashResult => ({
      hash,
      hashParams: parsedHashGlobal,
      initialDupeFragment,

      getHashParam: (key: string) => parsedHashGlobal[key],
      hasHashParam: (key) => key in parsedHashGlobal,

      setHashParam,
      clearHash,

      removeHashParam: (key: string) => {
        debug('remove hash param', key, parsedHashGlobal);

        setHashParam({
          [key]: undefined,
        });
      },

      getPrevHashParams: () => {
        const { prevHash } = history.state;
        return prevHash ? fromQueryString(prevHash) : {};
      },

      backToBeforeFirstHash: () => {
        debug('back to before first hash', history.state);

        const { hashIndex, hasInitialHash } = history.state;

        if (hashIndex === undefined || hashIndex < 0 || hasInitialHash) {
          debug(`window.hashIndex ${hashIndex}: fallback to clearHash()`);

          clearHash();
          return;
        }

        debug('go back', -(hashIndex + 1));
        history.go(-(hashIndex + 1));
      },
    }),
    [hash, parsedHashGlobal, setHashParam]
  );
};

export default useHash;
